<template>
  <div class="players view">
    <app-header :title="__('view.ingame.players.title')"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{__('common.total')}}:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <div class="control-bar__search mr">
        <select-reference
            v-model="organizationId"
            :endpoint="'/ingame/civilization/organization'"
            :nothing-selected-message="__('view.ingame.constructs.filter_by_organizations')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <text-field
          class="control-bar__search"
          v-model="searchTerm"
          :label="__('common.search_term')"
          max-length="50"
          :hide-details="true"
          :clearable="true"
          @keyup:enter="refreshDelayed(100)"
          @click:clear="refreshDelayed(100)"
      />
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh()" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
        <div class="control-bar__button" :title="__('common.add')" @click="addPlayer()" v-slashes v-if="hasOneRoles([])">
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/add.svg"
               alt="add"/>
        </div>
      </div>
    </div>
    <div class="scroll-horizontal-overflow">
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <data-table
          :records="players"
          :fields="dataTableFields"
          :record-actions="recordActions"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loading"
          @editPlayer="editPlayer"
          @openCommunityPage="openCommunityPage"
          @sort="sort"
          @deleteRecord="deleteRecord"
          @showChangeLog="showPlayerLogs"
      />
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
    </div>
    <edit-player ref="editPlayer" @updated="refresh" @created="editPlayer"/>
    <player-logs ref="playerLogs"/>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import apiSecured from '../../api/secured';
  import DataTable from '../../components/DataTable';
  import AppHeader from '../../components/AppHeader';
  import EditPlayer from '../../components/Ingame/EditPlayer';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { paginationMixin } from "../../mixins/pagination";
  import { pageTitleMixin } from "../../mixins/pageTitle";
  import PlayerLogs from "../../components/Ingame/PlayerLogs";
  import { userMixin } from "../../mixins/user";

  const MEMBERSHIP_TYPES = {
    MEMBER: 0,
    LEGATE: 1,
    SUPERLEGATE: 2
  }

  export default {
    mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin,userMixin],
    components: {
      PlayerLogs,
      AppHeader,
      DataTable,
      EditPlayer
    },
    data: () => ({
      loading: false,
      players: [],
      organizationId: null,
      dataTableFields: [],
      recordActions: [],
    }),
    watch: {
      organizationId() {
        this.pagination.page = 1;
      },
    },
    methods: {
      showPlayerLogs({id}) {
        this.$refs.playerLogs.showDialog(id);
      },
      refreshDelayed(delay) {
        setTimeout(this.refresh, delay);
      },
      refresh(callback) {
        this.loading = true;
        let params = this.getPaginationParams();
        if (this.organizationId !== null) {
          params.organizationId = this.organizationId;
        }
        this.$router.push({ name: this.$route.name, query: params }).catch(err => {
        });
        apiSecured.get('/ingame/civilization/player', { params }).then(async (res) => {
          this.players = res.data;
          await this.enrichRecords();
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification(this.__('common.players_loaded'));
          if (typeof callback === 'function') {
            callback();
          }
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      async enrichRecords() {
        const orgIds = [];
        this.players.forEach(player => {
          player.organizationMemberships.forEach(({ organizationId }) => {
            if (!orgIds.includes(organizationId)) {
              orgIds.push(organizationId);
            }
          });
        });
        let organizations = [];
        if (orgIds.length > 0) {
          await apiSecured.get(`/ingame/civilization/organization/(${orgIds.join(',')})`, {
            params: {
              fields: 'id,name'
            },
          }).then((res) => {
            organizations = res.data;
            this.showInfoNotification('Parents have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
          this.players = this.players.map(player => {
            player.membershipNames = player.organizationMemberships.filter(membership => membership.type === MEMBERSHIP_TYPES.MEMBER).map(membership => {
              return organizations.find(organization => organization.id === membership.organizationId).name;
            }).join(', ');
            player.legationNames = player.organizationMemberships.filter(membership => membership.type === MEMBERSHIP_TYPES.LEGATE).map(membership => {
              return organizations.find(organization => organization.id === membership.organizationId).name;
            }).join(', ');
            player.superlegationNames = player.organizationMemberships.filter(membership => membership.type === MEMBERSHIP_TYPES.SUPERLEGATE).map(membership => {
              return organizations.find(organization => organization.id === membership.organizationId).name;
            }).join(', ');
            return player;
          });
        }
      },
      addPlayer() {
        this.$refs.editPlayer.showCreate();
      },
      editPlayer(player) {
        this.$refs.editPlayer.showEdit(player);
      },
      openCommunityPage(player) {
        window.open(`https://community.dualthegame.com/accounts/profile/${player.nqUserId}/`)
      },
      deleteRecord(record) {
        Swal.fire({
          title: this.__('common.are_you_sure'),
          text: this.__('common.do_you_want_to_delete'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.__('common.yes_delete_it')
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            let endpoint = '/ingame/civilization/player/' + record.id;
            apiSecured.delete(endpoint).then((res) => {
              this.loading = false;
              this.showSuccessNotification(this.__('common.deleted'));
              this.refresh();
            }).catch((error) => {
              this.loading = false;
              this.showErrorNotifications(error);
              this.refresh();
            });
          }
        });
      },
    },
    created() {
      this.setPageTitle(this.__('entity.common.players'));
      if ('organizationId' in this.$route.query) {
        const orgId = this.$route.query.organizationId;
        if (orgId !== null && orgId !== undefined) {
          this.organizationId = orgId;
        }
      }
      this.dataTableFields = [
        { name: "avatar", title: 'Avatar', type: "image" },
        { name: "id", title: this.__('entity.common.id'), type: "default" },
        { name: "name", title: this.__('entity.common.name'), type: "default" },
        { name: "founderStatus", title: this.__('entity.ingame.player.founder_status'), type: "default" },
        { name: "joined", title: this.__('entity.ingame.player.joined'), type: "datetime" },
        {
          name: "membershipNames",
          title: this.__('entity.common.organizations'),
          type: "default",
          disableSorting: true
        },
        {
          name: "legationNames",
          title: this.__('entity.ingame.player.legate_in'),
          type: "default",
          disableSorting: true
        },
        {
          name: "superlegationNames",
          title: this.__('entity.ingame.player.superlegate_in'),
          type: "default",
          disableSorting: true
        },
        { name: "nq", title: 'Novaquark', type: "boolean-inverted" },
        { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
        { name: "updatedAt", title: this.__('entity.common.updated_at'), type: "datetime" }
      ];
      this.recordActions = [
        { title: this.__('view.ingame.players.open_community_page'), icon: 'open-in-new', event: 'openCommunityPage' },
        { title: this.__('showPlayerLogs'), icon: 'change-log', event: 'showChangeLog' },
        { title: this.__('component.ingame.edit_player.title'), icon: 'pencil', event: 'editPlayer', restrictRoles: [] },
        { title: this.__('view.ingame.players.delete_player'), icon: 'bin', event: 'deleteRecord', restrictRoles: [] }
      ];
      this.refresh();
    }
  }
</script>
